import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import MainPage from './components/pages/MainPage';
import PartnersPage from './components/pages/PartnersPage';
import ContactPage from './components/pages/ContactPage';

import './css/App.css';

function App() {
  return (
    <Router>
      <div className="animated-background">
        <div className="App">
          <NavBar />
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/partners" element={<PartnersPage />} />
            <Route path="/contact" element={<ContactPage />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;