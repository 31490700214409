import React from 'react';

import '../../css/GeneralPage.css';
import '../../css/GeneralButton.css';
import '../../css/pages/TeamPage.css';

import danImage from '../../assets/team/danli.jpeg';
import rishiImage from '../../assets/team/rishili.jpeg';
import marianImage from '../../assets/team/marianli.jpeg';
import jackImage from '../../assets/team/jackli.jpeg';


function TeamTable() {
    return (
        <table>
            <tbody>
                <tr>
                    <td className="person-cell">
                        <div className="person-wrapper">
                            <a href="https://www.linkedin.com/in/daniel-aliber" target="_blank" rel="noopener noreferrer">
                                <img src={danImage} alt="Dan Aliber" className="person-image" />
                            </a>
                            <div>
                                <strong>Dan Aliber</strong><br />CEO
                            </div>
                        </div>
                    </td>
                    <td className="person-cell">
                        <div className="person-wrapper">
                            <a href="https://www.linkedin.com/in/rsharma441/" target="_blank" rel="noopener noreferrer">
                                <img src={rishiImage} alt="Rishi Sharma" className="person-image" />
                            </a>
                            <div>
                                <strong>Rishi Sharma</strong><br />CTO
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="person-cell">
                        <div className="person-wrapper">
                            <a href="https://www.linkedin.com/in/mtasuncion" target="_blank" rel="noopener noreferrer">
                                <img src={marianImage} alt="Marian Asuncion" className="person-image" />
                            </a>
                            <div>
                                <strong>Marian Asuncion</strong><br />CMO
                            </div>
                        </div>
                    </td>
                    <td className="person-cell">
                        <div className="person-wrapper">
                            <a href="https://www.linkedin.com/in/jack-misko" target="_blank" rel="noopener noreferrer">
                                <img src={jackImage} alt="Jack Misko" className="person-image" />
                            </a>
                            <div>
                                <strong>Jack Misko</strong><br />COO
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

export default TeamTable;