import React, { useEffect, useState } from 'react';
// import ChatBotComponent from '../ChatBotComponent';
// import OnboardingChatComponent from '../OnboardingChatComponent';
import CalendarComponent from '../CalendarComponent';


import '../../css/GeneralPage.css';
import '../../css/GeneralButton.css';
import '../../css/pages/MainPage.css';

import TeamTable from './TeamPage'


function MainPage() {
    // const [isChatbotVisible, setChatbotVisible] = useState(false);
    // const chatbotRef = useRef(null);
    const [showTagline, setShowTagline] = useState(true);
    const [fadeInWaitlist, setFadeInWaitlist] = useState(false);

    // const toggleChatbotVisibility = () => {
    //     setChatbotVisible(prevState => !prevState);
    // };

    // const handleClickOutside = (event) => {
    //     if (chatbotRef.current && !chatbotRef.current.contains(event.target)) {
    //         setChatbotVisible(false);
    //     }
    // };

    // useEffect(() => {
    //     document.addEventListener('mousedown', handleClickOutside);

    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, []);

    useEffect(() => {
        const timer1 = setTimeout(() => {
            setShowTagline(false);
        }, 1500);

        const timer2 = setTimeout(() => {
            setFadeInWaitlist(true);
        }, 1550);

        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
        };
    }, []);
    return (
        <div id="fullpage">
            <div className="pagecontainer" id="mainpage-section">
                <div className="page-left-section">
                    <div className="sticky-box">
                        {showTagline && (
                            <p className={`${showTagline ? 'fade-in' : 'fade-out'}`}>less waiting, more dating</p>
                        )}
                        {!showTagline && (
                            <>
                                <div id="vertical-spacer" style={{ width: '100%', height: '20px' }}></div>
                                <div className="fade-in-delay" style={{ fontSize: '28px' }}>
                                    Click, chat and join our waitlist
                                </div>
                                <button 
  className="fade-in-delay" 
  onClick={() => window.open('https://amari.beehiiv.com/subscribe', '_blank', 'noopener,noreferrer')} 
  style={{ fontSize: '22px' }}
>
  Join waitlist
</button>
                            </>
                        )}
                    </div>
                </div>
                {fadeInWaitlist && (
                    <div className="page-right-section fade-in">
                        <div className="mobile-page-left-section">
                            <h1>amari</h1>
                            <div className="mobile-page-left-text" style={{ fontSize: '28px' }}>
                                Click, chat and join our waitlist
                            </div>
                            <button 
  className="mobile-page-left-button" 
  onClick={() => window.open('https://amari.beehiiv.com/subscribe', '_blank', 'noopener,noreferrer')} 
  style={{ fontSize: '22px' }}
>
  Join waitlist
</button>                        </div>
                        <section className="first-information-section">
                            <header>
                                <h2>About Us</h2>
                            </header>
                            <p>Welcome to Amari, the world&apos;s first dating app. Unlike the apps that prioritize endless swiping, we believe everyone deserves:</p>
                            <ul className="no-bullets">
                                <li><span className="icon">💖</span> Quality dates in days, not months</li>
                                <li><span className="icon">🔍</span> People with the same intention</li>
                                <li><span className="icon">🎉</span> Fun and fresh date experiences</li>
                                <li><span className="icon">✨</span> Feeling great about themselves the entire time</li>
                            </ul>
                            <p>Our smart system starts by learning what makes you great and your needs, then finds you the best potential partners, and then gets you the best date experiences to make dating fun! </p>
                        </section>
                        <section className="main-information-section">
                            <header>
                                <h2>Events</h2>
                            </header>

                            <CalendarComponent />

                        </section>
                        <section className="main-information-section">
                            <header>
                                <h2>Blog & Newsletter </h2>
                            </header>
                            <div id="vertical-spacer" style={{ width: '100%', height: '20px' }}></div>
                            Check out our <a href="https://amari.beehiiv.com/" target="_blank" rel="noopener noreferrer"> blog </a> for the latest insights on dating, relationships, and life. <a href="https://amari.beehiiv.com/subscribe" target="_blank" rel="noopener noreferrer">Subscribe</a> to our newsletter to stay updated!
                        </section>
                        <section className="main-information-section">
                            <header>
                                <h2>Get Invovled</h2>
                            </header>
                            <div id="vertical-spacer" style={{ width: '100%', height: '20px' }}></div>
                            <ul>
                                <li><b>Survey:</b> Help us improve by sharing your dating experiences. Fill out our survey <a href="https://docs.google.com/forms/d/e/1FAIpQLScT1Rz5EjTSkKgbErEKbqVeRXOli9d3V5fQ14IZW23IYXMzIA/viewform?pli=1" target="_blank" rel="noopener noreferrer"> here</a>.</li>
                                <li><b>Partners:</b> We&apos;re looking for hospitality and event vendors in the Washington, DC area to partner with us in promoting in-person dates. Contact us at <a href="mailto:amari.matches@gmail.com">amari.matches@gmail.com</a> to explore partnership opportunities.</li>
                            </ul>
                        </section>
                        <header>
                            <h2>Team</h2>
                        </header>
                        Meet the people behind Amari.
                        <section className="table-information-section">
                            <TeamTable />
                        </section>


                    </div>


                )}
            </div>

        </div>
    );
}

export default MainPage;
