import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../css/CalendarComponent.css';  // Import the custom CSS

const CalendarComponent = () => {
  const [date, setDate] = useState(new Date());
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {

        const response = await fetch(`https://docs.google.com/spreadsheets/d/1oK379CxOK1hC5j6txkI6m1l_dmioIeB6tXJDGHAR5qY/pubhtml?${new Date().getTime()}`);
        const text = await response.text();

        // Create a DOM parser
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, 'text/html');

        // Select the table rows (you might need to inspect the structure to find the correct selector)
        const rows = doc.querySelectorAll('table.waffle tbody tr');
        const eventsData = [];

        rows.forEach((row, index) => {
          if (index > 0) { // Skip the header row
            const cells = row.querySelectorAll('td');
            eventsData.push({
              title: cells[0].innerText,
              description: cells[1].innerText,
              location: cells[2].innerText,
              address: cells[3].innerText,
              location_url: cells[4].innerText,
              date: cells[5].innerText,
              startTime: cells[6].innerText,
              endTime: cells[7].innerText,
              url: cells[8].innerText,
            });
          }
        });

        const now = new Date();
        const filteredEvents = eventsData.filter(event => new Date(event.date) >= now);
        
        setEvents(filteredEvents);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchEvents();
  }, []);

  const sortedEvents = events.sort((a, b) => new Date(a.date) - new Date(b.date));

  const tileClassName = ({ date, view }) => {
    if (view === 'month') {
      const eventDates = events.map(event => new Date(event.date).toDateString());
      if (eventDates.includes(date.toDateString())) {
        return 'highlight';
      }
    }
    return null;
  };

  return (
    <div>
      Our in-person events complement your online dating experience. From one-on-one dates to happy hours with fellow singles, we create opportunities to meet face-to-face and reduce screen time. Discover our latest events below.
      <div id="vertical-spacer" style={{ width: '100%', height: '20px' }}></div>

      <Calendar
        onChange={setDate}
        value={date}
        tileClassName={tileClassName}
        navigationLabel={({ label, view }) =>
          view === 'month' ? label.split(' ')[0] : label
        }
      />

      <div id="vertical-spacer" style={{ width: '100%', height: '20px' }}></div>

      <div className="events-list">
        {sortedEvents.map((event, index) => (
          <div key={index} className="event-card">
            <div className="event-date">
              <div className="event-day">{new Date(event.date).getDate()}</div>
              <div className="event-month">{new Date(event.date).toLocaleString('default', { month: 'short' })}</div>
            </div>
            <div className="event-details">
              <h3 className="event-title">{event.title}</h3>
              <p className="event-time">{event.startTime} - {event.endTime}</p>
              <p className="event-location"><a href={event.location_url} target="_blank" rel="noopener noreferrer">{event.location}</a></p>
              <p className="event-description">{event.description}</p>
              <div className="event-actions">
                <button className="event-button" onClick={() => window.open(event.url, '_blank')}>RSVP</button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarComponent;
