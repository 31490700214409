import React, { useState } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';


import '../../css/GeneralPage.css';
import '../../css/GeneralButton.css';
import '../../css/pages/PartnersPage.css';

Modal.setAppElement('#root');

function PartnersPage({ modalIsOpen, setModalIsOpen }) {
    const [offers, setOffers] = useState([{ dayOfWeek: '', time: '', offerDescription: '' }]);

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission logic here, including offers
        closeModal();
    };

    const addOffer = () => {
        setOffers([...offers, { dayOfWeek: '', time: '', offerDescription: '' }]);
    };

    const removeLastOffer = () => {
        if (offers.length > 1) {
            setOffers(offers.slice(0, -1));
        }
    };

    const handleOfferChange = (index, field, value) => {
        const newOffers = offers.map((offer, i) => {
            if (i === index) {
                return { ...offer, [field]: value };
            }
            return offer;
        });
        setOffers(newOffers);
    };

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Add Business Information"
            className="modal"
            overlayClassName="overlay"
        >
            <h2>Add Business Information</h2>
            <div className="partnerformspacer"></div>
            <form onSubmit={handleSubmit} className="partner-form">
                <label>
                    Add Business Name:
                    <input type="text" name="businessName" required />
                </label>
                <label>
                    Add Google Maps link:
                    <input type="url" name="googleMapsLink" required />
                </label>
                <label>
                    Phone number:
                    <input type="tel" name="phoneNumber" required />
                </label>
                <label>
                    Email:
                    <input type="email" name="email" required />
                </label>
                <label>
                    Add description:
                    <textarea name="description" required></textarea>
                </label>
                <label>
                    Add picture (must have 1):
                    <input type="file" name="picture" accept="image/*" required />
                </label>
                {offers.map((offer, index) => (
                    <fieldset key={index}>
                        <legend>Offer {index + 1}</legend>
                        <label>
                            Select Day of the week:
                            <select
                                name="dayOfWeek"
                                value={offer.dayOfWeek}
                                onChange={(e) => handleOfferChange(index, 'dayOfWeek', e.target.value)}
                                required
                            >
                                <option value="">Select a day</option>
                                <option value="monday">Monday</option>
                                <option value="tuesday">Tuesday</option>
                                <option value="wednesday">Wednesday</option>
                                <option value="thursday">Thursday</option>
                                <option value="friday">Friday</option>
                                <option value="saturday">Saturday</option>
                                <option value="sunday">Sunday</option>
                            </select>
                        </label>
                        <label>
                            Time:
                            <input
                                type="time"
                                name="time"
                                value={offer.time}
                                onChange={(e) => handleOfferChange(index, 'time', e.target.value)}
                                required
                            />
                        </label>
                        <label>
                            Description:
                            <textarea
                                name="offerDescription"
                                value={offer.offerDescription}
                                onChange={(e) => handleOfferChange(index, 'offerDescription', e.target.value)}
                                required
                            ></textarea>
                        </label>

                    </fieldset>
                ))}
                <button type="button" onClick={addOffer}>Add Another Offer</button>
                <button type="button" onClick={removeLastOffer}>Remove Offer</button>
                <div className="partnerformspacer"></div>
                <button type="submit">Submit</button>
                <button type="button" onClick={closeModal}>Cancel</button>
            </form>
        </Modal>
    );
}

export default PartnersPage;


PartnersPage.propTypes = {
    modalIsOpen: PropTypes.bool.isRequired,
    setModalIsOpen: PropTypes.func.isRequired

};
