import React from 'react';
import MailIcon from '@mui/icons-material/Mail';
import PropTypes from 'prop-types';

import '../../css/GeneralPage.css';
import '../../css/GeneralButton.css';
import '../../css/pages/ContactPage.css';


function ContactPage() {
    return (
        <div className="contactpagecontainer" id="contact-section" >
            <h1 className="contact-title">Contact Us</h1>
            <div className="contact-container">
                <ContactInfo
                    icon={<MailIcon fontSize="large" />}
                    topText=""
                    bottomText="amari.matches@gmail.com"
                />
            </div>
        </div>
    );
}

function ContactInfo({ icon, topText, bottomText }) {
    return (
        <div className="contact-info">
            <div className="contact-icon">
                {icon}
            </div>
            <div>{topText}</div>
            <div>
                <a href={`mailto:${bottomText}`} className="email-link">
                    {bottomText}
                </a>
            </div>
        </div>
    );
}

ContactInfo.propTypes = {
    icon: PropTypes.element.isRequired,
    topText: PropTypes.string.isRequired,
    bottomText: PropTypes.string.isRequired
};

export default ContactPage;