// NavBar.js
// import React, { useState } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/amari_logo_white_terracotta.png';
import '../css/NavBar.css';
// import PartnersPage from './pages/PartnersPage';

function NavBar() {

    // const [modalIsOpen, setModalIsOpen] = useState(false);

    // const openModal = () => {
    //     setModalIsOpen(true);
    // };

    const scrollToMain = () => {
        const mainSection = document.getElementById('mainpage-section');
        if (mainSection) {
            mainSection.scrollIntoView({ behavior: 'smooth' });
        }
    };


    return (
        <div className="nav-container">
            <div className="nav-spacer"></div>
            <div className="nav-content">
                <div className="nav-logo">
                    <Link to="/" onClick={scrollToMain}>
                        <img src={logo} alt="Logo" className="nav-logo-image" />
                    </Link>
                </div>
                <div className="nav-flex"></div>
                {/* <div className="nav-button">
                    <Link to="/admin">
                        <button className="nav-btn" >
                            Admin
                        </button>
                    </Link>
                </div>
                <div className="nav-button">
                    <button className='nav-btn' onClick={openModal}>
                        Partners
                    </button>
                </div> */}
                <div className="nav-button">
                    <Link to="/contact">
                        <button className="nav-btn" >
                            Contact Us
                        </button>
                    </Link>
                </div>
                {/* <PartnersPage modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} /> */}
            </div>
        </div>
    );
}

export default NavBar;